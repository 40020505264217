<template>
  <div class="pb-2">
    <portal to="tab-title">
      <div>
        <h4 class="text-center font-weight-bolder">
          {{ $t('Catalog') }}
        </h4>
      </div>
    </portal>
    <l-table
      ref="lTableRef"
      :table-config-options="getTableConfigOptions()"
      :module-name="MODULE_NAME"
      :table-columns="tableNonStockColumns"
      :is-delete="true"
      :filter-class="new Products()"
      row-clicked="home-catalog-view"
      :has-action="true"
      :create-page-url="{ name: 'home-catalog-create' }"
      :create-btn-dashed="true"
      :is-suggestion-table="true"
      trash-list-path="home-main-catalog-trash-list-non-stocks"
      :can-create="ACCESS_ABILITY_CATALOG"
      :can-show-trash-list="ACCESS_ABILITY_CATALOG"
      action-name="changeStatus"
      :query-params="getQueryParams()"
      :confirm-notification-options="getConfirmNotificationOptions()"
    >
      <template #cell(name)="{ data }">
        <!--        <div-->
        <!--            v-b-tooltip.noninteractive.hover-->
        <!--            class="name-cell"-->
        <!--            :title="getValue(data, 'name') ? getValue(data, 'name') : '—'"-->
        <!--        >-->
        <feather-icon
          icon="LNonStockIcon"
          size="26"
          class="mr-1"
        />
        {{ getValue(data, 'name') ? getValue(data, 'name') : '—' }}
        <!--        </div>-->
      </template>
      <template #cell(icon)="{ data }">
        <feather-icon
          :icon="renderIcon(data.item)"
          size="24"
          style="margin-bottom: 5px"
          class="cursor-pointer defaultIconColor"
        />
      </template>
      <!--      <template #cell(assets)="{ data }">-->
      <!--        <div class="text-center">-->
      <!--          {{ getValue(data, 'inventory_item_count') ? getValue(data, 'inventory_item_count') : '—' }}-->
      <!--        </div>-->
      <!--      </template>-->
      <template #cell(is_active)="{ data }">
        {{ data.item.is_active === null ? '—' : data.item.is_active ? 'Active' : 'Inactive' }}
      </template>
      <template #table-top-right-side-extras-prev="{ data }">
        <actions
          icon-name="LImportIcon"
          title="Import"
          :b-drop-down-items="exportButtonItems"
          @actionHandler="importActions"
        />
        <actions
          icon-name="LExportIcon"
          title="Export"
          :b-drop-down-items="exportButtonItems"
          @actionHandler="exportActions"
        />
      </template>
    </l-table>
    <export-c-s-v
      ref="export-c-s-v"
      :module-name="MODULE_NAME"
    />
    <import-c-s-v
      ref="import-c-s-v"
      :module-name="MODULE_NAME"
      @refetchTable="refetchData"
    />
  </div>
</template>

<script>

import ListComponent from '@/views/main/catalog/components/ListComponent.vue'
import { mapState } from 'vuex'
import ImportCSV from '@/views/components/Modals/ImportCSV.vue'
import ExportCSV from '@/views/components/Modals/ExportCSV.vue'
import LTable from '@/views/components/LTable/LTable.vue'
import Actions from '@/views/components/Actions.vue'
import Products from '@/views/components/filters/fields/Products'
import {
  CATALOG_TYPE_COMPONENT,
  CATALOG_TYPE_CONTAINER,
  CATALOG_TYPE_NON_STOCK,
  CATALOG_TYPE_PRODUCT,
} from '@/views/main/catalog/catalogType'
import config from '@/views/main/catalog/catalogConfig'
import { VBTooltip } from 'bootstrap-vue'

export default {
  name: 'ContainersList',
  components: {
    Actions, LTable, ExportCSV, ImportCSV, ListComponent,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  computed: {
    Products() {
      return Products
    },
    ...mapState('listModule', ['productNonStocks']),
    exportButtonItems() {
      return this.$store.state[this.MODULE_NAME].exportButtonItems
    },
  },
  created() {
    this.$parent.activeComponent = this.productNonStocks
    this.$store.commit(`${this.MODULE_NAME}/GET`, {
      type_id: CATALOG_TYPE_NON_STOCK,
    })
  },
  methods: {
    getQueryParams() {
      return {
        is_active: false,
      }
    },
    getConfirmNotificationOptions() {
      return { text: 'It is possible to revert this', confirmButtonText: 'Yes, deactivate it' }
    },
    refetchData() {
      this.$refs.lTableRef.refetchData()
    },
    renderIcon(data) {
      let iconP
      switch (data.type_id) {
        case CATALOG_TYPE_COMPONENT:
          iconP = 'LComponentIcon'
          break
        case CATALOG_TYPE_CONTAINER:
          iconP = 'LContainerIcon'
          break
        default:
          iconP = ''
      }
      return iconP
    },
    getValue(data, tableColumnName) {
      return data.item[tableColumnName] ?? {}
    },
    getTableConfigOptions() {
      return {
        endpoint: `${this.MODULE_NAME}/nonStocksActiveList`,
      }
    },
    exportActions({ btnTitle, actionType }) {
      this.$store.commit(`${this.MODULE_NAME}/SET_EXPORT`, {
        title: btnTitle,
        actionType,
        query: this.$refs.lTableRef.totalQuery,
      })
      this.$refs['export-c-s-v'].show()
    },
    importActions({ btnTitle, actionType }) {
      this.$store.commit(`${this.MODULE_NAME}/SET_IMPORT`, {
        title: btnTitle,
        actionType,
        query: this.$refs.lTableRef.totalQuery,
      })
      this.$refs['import-c-s-v'].show()
    },
  },
  setup() {
    const MODULE_NAME = 'catalogs'
    const { tableNonStockColumns, ACCESS_ABILITY_CATALOG } = config()
    return {
      MODULE_NAME,
      tableNonStockColumns,
      ACCESS_ABILITY_CATALOG,
    }
  },
}
</script>
